import { withQuery, withBase } from 'ufo'
import type { ProviderGetImage } from '@nuxt/image'

export const getImage: ProviderGetImage = (src, { modifiers = {}, baseURL, scale } = {}) => {
  const { width, height, format, quality, fit } = modifiers
  const operations = {}

  if (width) {
    operations.w = width
  }
  if (height) {
    operations.h = height
  }
  if (format) {
    operations.f = format
  }
  if (quality) {
    operations.q = quality
  }
  if (fit) {
    operations.m = fit
  }
  if (scale) {
    operations.scale = scale
  }

  return {
    url: withBase(withQuery(src, operations), baseURL),
  }
}
